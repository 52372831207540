.boxSendMailPw {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    text-align: center;
    margin-bottom: 30px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
  }
  .des {
    text-align: center;
    margin: 10px 0 50px 0;
  }
  .sendMailPwForm {
    width: 550px;
    max-width: 90%;
    padding: 30px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .sendMailErr {
    margin-top: 10px;
    color: red;
    text-align: center;
  }
}
