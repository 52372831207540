.filterContainer {
  margin-bottom: 50px;
}

.wrapCounterUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
