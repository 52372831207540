.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    font-weight: bold;
  }
  &__btn_login {
    height: 40px;
  }
  &__form {
    width: 550px;
    max-width: 90%;
    padding: 30px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  &__error {
    margin-top: 10px;
    color: red;
    text-align: center;
  }
  &__logo {
    text-align: center;
    margin-bottom: 30px;
  }
  &__forgot___pw {
    text-align: right;
    margin-top: 14px;
  }
}
