@import 'styles/variables.scss';

.layout {
  display: flex;
  width: 100vw;

  .sidebar {
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid;
    background-color: #001529;
  }
  .boxMainContent {
    padding: 0 20px 20px 20px;
    width: calc(100% - 250px);
    max-height: 100vh;
    overflow-y: scroll;
    background-color: $light-primary-background;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
      .boxInfo {
        display: flex;
        align-items: center;
        button {
          margin-right: 34px;
        }

        .name {
          position: relative;
          padding: 0 3rem;
          cursor: pointer;
          align-self: stretch;
          display: flex;
          align-items: center;

          .profile-menu {
            width: auto;
            position: absolute;
            left: 50%;
            top: calc(100% - 0.5rem);
            z-index: 99;

            visibility: visible;
            opacity: 0;
            transform: translateX(-50%) scaleY(0);
            transform-origin: top;
            transition: opacity 0.2s ease-out 0.1s, transform 0.25s;

            .menu {
              width: 100%;
              padding: 1rem 2.5rem;
              &:hover {
                background-color: #186f6613;
                border-radius: 5px;
                .menu-item {
                  font-weight: bold;
                  transform: translateY(-1px);
                }
              }
              .menu-item {
                transition: all 0.25s;
                width: 100%;
                margin-bottom: 0;
              }
            }
          }

          &:hover {
            .profile-menu {
              visibility: visible;
              opacity: 1;
              transform: translateX(-50%) scaleY(1);
            }
          }
        }

        .walletAddress {
          border: 1px solid #aaa;
          padding: 4px 15px;
          border-radius: 22px;
          cursor: pointer;
        }
      }
    }
  }
  .children {
    margin-top: 20px;
  }
}
.walletAddressCopy {
  border: 1px solid #aaa !important;
  padding: 4px 15px;
  border-radius: 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  .accountTooltip {
    margin-right: 10px;
  }
}
