.wrapMg {
  margin-bottom: 50px;
}

.textTitleDomestic {
  font-size: 20px;
  font-weight: normal;
}

.textOwner {
  font-size: 20px;
  font-weight: normal;

}

.wrapBack {
  background: #18c3af;
  border-radius: 6px;
  width: 80px;
  padding: 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;

}

.wrapInfo {
  margin-top: 20px;
}
