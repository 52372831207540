@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(../assets/fonts/Inter-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter-Bold'), url(../assets/fonts/Inter-Bold.woff) format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap');
@import url('https://rsms.me/inter/inter.css');

@import './utils.scss';
@import './reset.scss';

@import './variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $primary-font-size;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
//
//.ant-menu .ant-menu-sub .ant-menu-inline {
//  background: #242424 !important;
//
//}

html {
  font-size: 62.5%;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: gray;
  }
}

/* Customize antd component go here */
.ant-card-body {
  padding: 0;
}
.ant-form-item-explain-error {
  margin-bottom: 10px;
}
.custom-table {
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
  }
}
.custom-form {
  .ant-form-item-control,
  .ant-picker {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0.8rem;

    &-row {
      flex-direction: column;
    }

    &-label {
      text-align: left;

      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $dark-gray;

      label {
        color: currentColor;
        margin-bottom: 0.4rem;
      }
    }
  }

  .ant-select-selector,
  .ant-input,
  .ant-picker {
    border-radius: 6px !important;
    min-height: 4rem !important;
  }

  .ant-form-item-control-input {
    .ant-input {
      &:disabled {
        color: #424242;
        background-color: #d8d8d8;
      }
    }
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    margin-top: 3px;
  }
  .weapon-select-box {
    .ant-select-selection-placeholder {
      margin-top: 9px;
    }
    .ant-select-selector {
      height: 50px;
    }
    .ant-select-item-option {
      height: 40px;
    }
  }
  .ant-form-item-has-error {
    .weapon-select-box {
      .ant-select-selector {
        border-color: #d9d9d9 !important;
      }
    }
  }
}
.form-refund {
  .ant-select-selection-item {
    height: 40px;
  }
}
.custom-quantity {
  .ant-input {
    border-radius: 6px !important;
    height: 40px;
  }
}

.ant-modal-title {
  text-align: center;
}

.ant-picker-range {
  width: 100%;
}

.text-700 {
  font-weight: 700;
}
.text-size-18 {
  font-size: 18px;
  margin-bottom: 10px;
}

.mt-50 {
  margin-top: 50px;
}

.mg-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.mg-5 {
  margin: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}

.mg-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.ml-0 {
  margin-left: 0px;
}

.loading-grey {
  display: flex;
  justify-content: center;
}

.boxViewNotification {
  .ant-image {
    width: 100%;
  }
}
.select-multiple {
  .ant-select-selection-overflow {
    overflow-y: scroll;
    max-height: 28px;
  }
}

.update-modal-form .ant-select-disabled .ant-select-selector,
.update-modal-form .ant-input[disabled] {
  color: #000000d9 !important;
}

.modal-custom {
  .ant-modal-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: $grey-secondary-color;
  }
}
