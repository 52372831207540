.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
