$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 767px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$primary-font-size: 1.4rem;

// Colors
$light-black-color: #29303d; //black1
$light-primary-background: #ffffff;
$active-color: rgba(24, 195, 175, 0.1);
$black-primary-color: #282828;
$black-secondary-color: #242424;
$grey-primary-color: #e3e3e3;
$grey-secondary-color: #18c4af;
$mint-green: #18c4af;
$dark-gray: #979797;

// Screen
$breakpoint-xxl: 1440px;
$breakpoint-xl: 1200px;
$breakpoint-desktop: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 428px;
$breakpoint-m-mobile: 375px;
$breakpoint-sm-mobile: 320px;
