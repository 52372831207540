@import './variables.scss';

.ant-modal-header,
.ant-modal-footer {
  border: none;
  border-radius: 1rem;
}

.ant-modal-content {
  border-radius: 1rem;
}

.ant-modal-title {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.4rem;

  color: $mint-green;
}
