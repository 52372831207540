@import 'styles/variables.scss';

.menu {
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  padding-top: 20px;
  .boxLogo {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  .item {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    padding-left: 20px;
  }
  .nav {
    &_item {
      height: 50px;
      padding: 0 3%;
      display: flex;
      align-items: center;
      // justify-content: center;
      text-align: center;
      transition: all 0.3s;
      color: white;
      font-weight: 500;
      text-decoration: none;
    }
    &_normal {
      width: 100%;

      &:hover {
        opacity: 0.6;
      }
    }
    &_active {
      width: 100%;
      background-color: $active-color;
      border-radius: 10px;
      svg,
      span {
        color: #18c3af;
      }
    }
    &_name {
      margin-left: 5px;
    }
  }
}

.profile {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 20px;
  color: white;
  margin-bottom: 20px;
  border-top: 2px solid white;
  &_icon {
    width: 20px;
    cursor: pointer;
  }
  .profileMenu {
    cursor: pointer;
    display: flex;
    gap: 5px;
    margin-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
.logo {
  width: 100%;
  margin-bottom: 30px;
}
.linkToHome {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-menu{
  background: #242424 !important;
}

.ant-menu-item-selected {
  background-color: rebeccapurple !important;

}
