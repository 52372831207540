.file-upload {
  &__error {
    color: red;
    margin-bottom: 10px;
  }
  &__loading {
    text-align: center;
    width: 250px;
  }
  &__result {
    position: relative;
  }
  &__audio-preview {
    margin-bottom: 10px;
  }
  &__image-preview {
    width: 250px;
    max-height: 250px;
    margin-bottom: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__delete-btn {
    position: absolute !important;
    top: 3px;
    left: 3px;
    z-index: 100;
    background-color: white !important;
    padding: 3px !important;
    height: 25px !important;
  }
  &__img {
    max-height: 250px;
    display: block;
    margin: 0 auto;
  }
  &__video {
    max-height: 250px;
    display: block;
  }
  &__input-label {
    margin-top: 8px;
    display: block;
    font-weight: bold;
  }
  button {
    outline: none !important;
    background: none;
    padding: 0;
    border: 0;
  }
  &__view-btn {
    margin-left: 10px;
  }
}

.file-detail {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  text-align: center;
  padding-right: 38px;
  margin-top: 20px;
  img {
    width: 600px;
    height: 400px;
    object-fit: contain;
  }
}
