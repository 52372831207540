.boxContent {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  .text {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .boxImg {
    border: 1px solid #ddd;
    padding: 10px;
    width: fit-content;
    margin-bottom: 30px;
    img {
      max-height: 300px;
      object-fit: cover;
    }
  }
}
